<template>
    <div class="ml-10 mr-10">
        <TitleBar v-if="this.$route.params.uuid === 'new'" :loading="loading">{{ $t("quote.new") }}</TitleBar>
        <TitleBar v-else
                  :disabled="loading"
                  :loading="loading"
                  :menu="[
                      {text: this.$t('quote.download'), callback: downloadQuote},
                      {text: this.$t('quote.duplicate'), callback: duplicateQuote},
                      {text: this.$t('quote.convert'), callback: () => {this.convertInvoice = true;}},
                      {text: this.$t('quote.delete'), callback: deleteElement, class: 'red--text'}
                  ]"
        >
            {{ $t("quote.edit") }}
            <!--            <v-btn
                            v-if="this.$route.params.uuid !== 'new'"
                            :loading="loading"
                            class="ml-10"
                            color="secondary"
                            elevation="2"
                            @click.prevent="downloadQuote"
                        >{{ $t("quote.download") }}
                        </v-btn>-->
        </TitleBar>

        <Tags v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Tags>

        <v-form @submit.prevent="formSubmit">
            <v-row>
                <v-col cols="12" md="4">
                    <v-autocomplete
                        v-model="contact"
                        :disabled="loading || this.$route.params.uuid !== 'new'"
                        :error-messages="contactErrors"
                        :items="contacts"
                        :label="$t('quote.contact')"
                        :no-data-text="$t('no_data')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.contact.$touch()"
                        @input="$v.contact.$touch()"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="category"
                        :disabled="loading"
                        :error-messages="categoryErrors"
                        :items="categories"
                        :label="$t('quote.category')"
                        item-text="name"
                        item-value="uuid"
                        outlined
                        @blur="$v.category.$touch()"
                        @input="$v.category.$touch()"
                    ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="currency"
                        :disabled="loading"
                        :error-messages="currencyErrors"
                        :items="currencies"
                        :label="$t('quote.currency')"
                        outlined
                        @blur="$v.currency.$touch()"
                        @input="$v.currency.$touch()"
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" md="4">
                    <DatePicker
                        v-model="emitted"
                        :disabled="loading"
                        :label="$t('quote.emitted')"
                    ></DatePicker>
                </v-col>
                <v-col cols="12" md="4">
                    <DatePicker
                        v-model="valid_until"
                        :disabled="loading"
                        :error-messages="validUntilErrors"
                        :label="$t('quote.valid_until')"
                        @blur="$v.valid_until.$touch()"
                        @input="$v.valid_until.$touch()"
                    ></DatePicker>
                </v-col>
                <v-col cols="12" md="4">
                    <v-select
                        v-model="status"
                        :disabled="loading"
                        :items="statues"
                        :label="$t('quote.status')"
                        outlined
                    ></v-select>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-textarea
                        v-model="notes"
                        :label="$t('quote.notes')"
                        auto-grow
                        outlined
                        rows="3"
                    ></v-textarea>
                </v-col>
            </v-row>

            <Items
                v-model="items"
                :disabled="loading"
                :label="$t('quote.items')"
            >
            </Items>

            <v-btn
                :loading="loading"
                color="secondary"
                elevation="2"
                type="submit"
            >{{ $route.params.uuid === "new" ? $t("quote.add") : $t("quote.update") }}
            </v-btn>
        </v-form>


        <!--        Fields -->

        <Attachments v-if="this.$route.params.uuid !== 'new'" :element="$route.params.uuid"></Attachments>

        <!--        <v-btn-->
        <!--            elevation="2"-->
        <!--            color="error"-->
        <!--            :loading="loading"-->
        <!--            @click.prevent="deleteElement"-->
        <!--            class="mt-10"-->
        <!--            v-if="this.$route.params.uuid !== 'new'"-->
        <!--        >{{ $t("quote.delete") }}-->
        <!--        </v-btn>-->

        <v-dialog
            v-model="convertInvoice"
            width="500"
        >
            <v-card>
                <v-card-title class="text-h5 primary mb-2 white--text">
                    {{ $t("quote.convert") }}
                </v-card-title>

                <v-card-text class="mt-5">
                    <v-form @submit.prevent="convertToInvoice">

                        <v-row>
                            <v-col cols="12">
                                <DatePicker
                                    v-model="period_start"
                                    :disabled="loading"
                                    :label="$t('invoice.period_start')"
                                ></DatePicker>
                            </v-col>
                            <v-col cols="12">
                                <DatePicker
                                    v-model="period_end"
                                    :disabled="loading"
                                    :label="$t('invoice.period_end')"
                                ></DatePicker>
                            </v-col>
                            <v-col cols="12">
                                <DatePicker
                                    v-model="due_date"
                                    :disabled="loading"
                                    :label="$t('invoice.due_date')"
                                ></DatePicker>
                            </v-col>
                        </v-row>

                        <v-btn
                            :loading="loading"
                            color="secondary"
                            elevation="2"
                            type="submit"
                        >{{ $t("quote.convert") }}
                        </v-btn>
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="secondary"
                        text
                        @click="convertInvoice = false"
                    >
                        {{ $t("close") }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Accounting from "../../helpers/Accounting";
import Tags from "../../components/Tags";
import Attachments from "../../components/Attachments";
import Currencies from "../../helpers/Currencies";
import DatePicker from "../../components/DatePicker";
import Items from "../../components/Items";
import Utils from "../../helpers/Utils";
import TitleBar from "@/components/TitleBar.vue";
import Statuses from "@/helpers/Statuses";
import DynamicElement from "@/helpers/DynamicElement";

export default {
    name: "Details",
    components: {TitleBar, Items, DatePicker, Attachments, Tags},
    async mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.quotes"),
                to: "/quotes",
                exact: true
            },
            {
                text: this.$route.params.uuid === "new" ? this.$t("quote.new") : this.$t("quote.edit"),
                to: "/quotes/" + this.$route.params.uuid,
                exact: true
            }
        ]);

        this.statues = Statuses.get(this.$i18n.locale, ["partial", "paid", "voided", "refunded"]);

        this.loading = true;
        if (this.$route.params.uuid === "new") {
            let contacts = await Accounting.get("/contacts?fields=name,uuid&per_page=-1&page=1").catch(() => {
            });
            this.contacts = contacts.data.contacts;
        }

        let categories = await Accounting.get("/categories?fields=name,uuid&per_page=-1&page=1").catch(() => {
        });
        this.categories = categories.data.categories;

        this.loading = false;

        if (this.$route.params.uuid !== "new") {
            this.loading = true;
            Accounting.get("/quotes/" + this.$route.params.uuid)
                .then(async response => {
                    const data = response.data;
                    this.items = data.items;
                    this.contact = data.contact;
                    this.currency = data.currency;
                    this.emitted = data.emitted;
                    this.valid_until = data.valid_until;
                    this.quote_number = data.quote_number;
                    this.notes = data.notes;
                    this.category = data.category;
                    this.status = data.status;

                    this.contacts = [{
                        name: await DynamicElement.cache("contact", DynamicElement.getCustomer, this.contact),
                        uuid: this.contact
                    }];

                    this.loading = false;
                }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        }
    },
    data: () => {
        return {
            loading: false,
            currencies: Currencies.get(),
            categories: [],
            statues: ["draft", "sent", "viewed", "approved", "partial", "paid", "received", "cancelled", "voided"],
            contacts: [],
            contact: null,
            category: null,
            currency: null,
            valid_until: null,
            emitted: null,
            items: [],
            notes: null,
            quote_number: null,
            status: null,

            convertInvoice: false,
            period_start: null,
            period_end: null,
            due_date: null
        };
    },
    methods: {
        convertToInvoice() {
            this.loading = true;

            Accounting.post("/incomes/invoices", {
                category: this.category,
                currency: this.currency,
                contact: this.contact,
                due_date: this.due_date,
                items: this.items,
                notes: this.notes,
                order_number: this.quote_number,
                period_start: this.period_start,
                period_end: this.period_end
            }).then(response => {
                this.status = "approved";
                this.formSubmit();

                Accounting.post("/tags", {
                    attached_at: this.$route.params.uuid,
                    key: "invoice",
                    value: response.data.uuid
                });
                Accounting.post("/tags", {
                    attached_at: response.data.uuid,
                    key: "quote",
                    value: this.$route.params.uuid
                });

                this.$store.commit("SET_ALERTS", [{
                    type: "success",
                    text: this.$t("invoice.added")
                }]);
                this.loading = false;
                this.convertInvoice = false;
                this.$router.push("/incomes/invoices/" + response.data.uuid);
            }).catch(error => {
                if (error.response) {
                    let errors = [];
                    for (const errorElement of error.response.data.errors) {
                        errors.push({
                            type: "error",
                            text: errorElement
                        });
                    }
                    this.$store.commit("SET_ALERTS", errors);
                } else if (error.request) {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: this.$t("api.no_response")
                    }]);
                } else {
                    this.$store.commit("SET_ALERTS", [{
                        type: "error",
                        text: error.message
                    }]);
                }
                this.loading = false;
            });
        },
        downloadQuote() {
            this.loading = true;
            Accounting.blob("/quotes/" + this.$route.params.uuid + "/document")
                .then(blob => {
                    let object = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = object;
                    link.download = this.quote_number;
                    link.target = "_blank";
                    document.body.appendChild(link);
                    link.dispatchEvent(
                        new MouseEvent("click", {
                            bubbles: true,
                            cancelable: true,
                            view: window
                        })
                    );
                    document.body.removeChild(link);
                    this.loading = false;
                }).catch(() => {
                this.loading = false;
            });
        },
        duplicateQuote() {
            this.$router.push({
                name: "quotes.details",
                params: {uuid: "new"}
            });
        },
        deleteElement() {
            this.$swal({
                title: this.$t("quote.delete_title"),
                text: this.$t("quote.delete_text"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: this.$t("quote.delete_yes"),
                cancelButtonText: this.$t("quote.delete_no"),
                confirmButtonColor: Utils.getColor(this, "error"),
                cancelButtonColor: Utils.getColor(this, "primary")
            }).then(result => {
                if (result.isConfirmed) {
                    this.loading = true;
                    this.$store.commit("SET_ALERTS", []);
                    Accounting.delete("/quotes/" + this.$route.params.uuid)
                        .then(() => {
                            this.$store.commit("SET_ALERTS", [{
                                type: "success",
                                text: this.$t("quote.deleted")
                            }]);
                            this.loading = false;
                            this.$router.push("/quotes");
                        })
                        .catch(error => {
                            if (error.response) {
                                let errors = [];
                                for (const errorElement of error.response.data.errors) {
                                    errors.push({
                                        type: "error",
                                        text: errorElement
                                    });
                                }
                                this.$store.commit("SET_ALERTS", errors);
                            } else if (error.request) {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: this.$t("api.no_response")
                                }]);
                            } else {
                                this.$store.commit("SET_ALERTS", [{
                                    type: "error",
                                    text: error.message
                                }]);
                            }
                            this.loading = false;
                        });
                }
            });
        },
        formSubmit() {
            this.$v.$touch();
            if (!this.$v.$invalid) {
                this.loading = true;

                let url = "/quotes";

                if (this.$route.params.uuid !== "new") {
                    url = url + "/" + this.$route.params.uuid;
                }

                this.$store.commit("SET_ALERTS", []);

                Accounting.post(url, {
                    category: this.category,
                    currency: this.currency,
                    contact: this.contact,
                    emitted: this.emitted,
                    items: this.items,
                    notes: this.notes,
                    valid_until: this.valid_until,
                    status: this.status
                }).then(response => {

                    this.loading = false;
                    if (this.$route.params.uuid === "new") {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("quote.added")
                        }]);
                        this.$router.push("/quotes/" + response.data.uuid);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "success",
                            text: this.$t("quote.updated")
                        }]);
                    }

                }).catch(error => {
                    if (error.response) {
                        let errors = [];
                        for (const errorElement of error.response.data.errors) {
                            errors.push({
                                type: "error",
                                text: errorElement
                            });
                        }
                        this.$store.commit("SET_ALERTS", errors);
                    } else if (error.request) {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: this.$t("api.no_response")
                        }]);
                    } else {
                        this.$store.commit("SET_ALERTS", [{
                            type: "error",
                            text: error.message
                        }]);
                    }
                    this.loading = false;
                });

            }
        }
    },
    mixins: [validationMixin],
    validations: {
        contact: {required},
        currency: {required},
        category: {required},
        valid_until: {required}
    },
    computed: {
        contactErrors() {
            const errors = [];
            if (!this.$v.contact.$dirty) return errors;
            !this.$v.contact.required && errors.push(this.$t("quote.contact_required"));
            return errors;
        },
        currencyErrors() {
            const errors = [];
            if (!this.$v.currency.$dirty) return errors;
            !this.$v.currency.required && errors.push(this.$t("quote.currency_required"));
            return errors;
        },
        categoryErrors() {
            const errors = [];
            if (!this.$v.category.$dirty) return errors;
            !this.$v.category.required && errors.push(this.$t("quote.category_required"));
            return errors;
        },
        validUntilErrors() {
            const errors = [];
            if (!this.$v.valid_until.$dirty) return errors;
            !this.$v.valid_until.required && errors.push(this.$t("quote.valid_until_required"));
            return errors;
        }
    }
};
</script>

<style scoped>

</style>